import Image from './components/Image';
import { Timeline, Collapse, Tooltip } from 'antd'
import './App.scss';
import Title from './components/Title';
import { useMediaQuery } from 'react-responsive';
import React from 'react';

function App() {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
  const [isSticky, setIsSticky] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 40) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className="app">
      <div className={`app-header-container ${(isSticky || isOpen) ? 'sticky' : ''}`}>
        <header className="app-header">
          <div className='left'>
            <Image className='logo' src='nav-logo.svg' />
            <span className='name'>Babel Tower</span>
          </div>
          {isDesktopOrLaptop ? (
            <div className='right'>
              <a className='link' target='_blank' href='/Babel_Tower_Whitepaper_V1.pdf'>White paper</a>
              <Tooltip title={'Coming soon...'}>
                <a className='button'>Launch App</a>
              </Tooltip>
            </div>
          ) : (
            <div className='right'>
              <Image className='menu-icon' onClick={() => { setIsOpen(!isOpen) }} src={isOpen ? 'close-circle.svg' : 'menu.svg'} />
            </div>
          )}
        </header>
      </div>
      {isDesktopOrLaptop ? null : (
        <div className={`drawer ${isOpen ? 'open' : 'close'}`}>
          <a className='link' target='_blank' href='/Babel_Tower_Whitepaper_V1.pdf'>White paper</a>
          <Tooltip title={'Coming soon...'}>
            <a className='button'>Launch App</a>
          </Tooltip>
        </div>
      )}
      <div className='container section1-container'>
        <div className='section-1'>
          <div className='left'>
            <div className='sub-title'>Unlocking Multichain Inscriptions</div>
            <div className='title'>Inscribe, Connect<br /> and Elevate.</div>
            <div className='content'>
              <div>Babel Tower seamlessly bridges the gap between Bitcoin and Ethereum using WBTC, allowing users to inscribe digital artifacts with smart contract concepts.</div>
              <div>Every inscription tells a story that transcends individual chains and becomes part of a shared legacy.</div>
            </div>
            <Tooltip title={'Coming soon...'}>
              <a className='button'>Get started</a>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='section-2'>
          {[
            {
              icon: 'fueling-inscriptions.svg',
              title: 'Fueling Inscriptions',
              content: 'Join us in shaping the future of decentralized heritage by depositing WBTC into our liquidity pools, users unlock the power to inscribe their unique artifacts. These pools serve as the catalyst for preserving history and connecting different blockchain ecosystems.'
            },
            {
              icon: 'immutable-inscriptions.svg',
              title: 'Immutable Inscriptions',
              content: 'Leverage Bitcoin blockchain immutability to safeguard inscriptions forever. Once an artifact is inscribed, it becomes part of an unalterable record on-chain.'
            },
            {
              icon: 'cultural-preservation.svg',
              title: 'Cultural Preservation',
              content: 'The community drives Babel Tower’s mission. Artists, collectors, and researchers collaborate to inscribe everything from rare NFTs to historical documents. Together, we elevate the significance of these artifacts, ensuring they withstand the test of time.'
            }
          ].map(({ icon, title, content }) => {
            return (
              <div className='item' key={title}>
                <Image className='icon' src={icon} />
                <div className='title'>{title}</div>
                <div className='content'>{content}</div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='container section3-container'>
        <div className='section-3'>
          <div className='title'>How it works?</div>
        </div>
      </div>
      <div className='img-container'>
        <Image className='img' src='how-it-works.png' />
      </div>
      <div className='container'>
        <div className='section-4'>
          {isDesktopOrLaptop ? (
            <Title align='right'>Our Roadmap</Title>
          ) : (
            <Title align='right'>Our Roadmap</Title>
          )}
          <Timeline
            className='timeline'
            mode='left'
            items={[
              isDesktopOrLaptop ? {
                label: '2023 Q4',
                children: (
                  <div>
                    <div>Voting of proposed blockchains to onboard for WBTC holders</div>
                    <ul>
                      <li>Eligibility: <span className='value'>Only WBTC holders can participate in the voting process</span></li>
                      <li>Voting Power: <span className='value'>Each WBTC token held corresponds to a certain amount of voting power.</span></li>
                      <li>Snapshot Voting: <span className='value'> Snapshots are taken of WBTC balances across addresses.</span></li>
                      <li>Vote Contract: <span className='value'>Deploy a smart contract that allows WBTC holders to cast their votes. The<br /> contract should track each address’s WBTC balance during the snapshot period.</span></li>
                      <li>Vote Weight: <span className='value'>Calculated based on its WBTC balance relative to the total supply of WBTC.</span></li>
                    </ul>
                  </div>
                ),
              } : {
                label: '',
                children: (
                  <div className='float'>
                    <div>2023 Q4 <span className='margin'>Voting of proposed blockchains to onboard for WBTC holders</span></div>
                    <ul>
                      <li>Eligibility: <span className='value'>Only WBTC holders can participate in the voting process</span></li>
                      <li>Voting Power: <span className='value'>Each WBTC token held corresponds to a certain amount of voting power.</span></li>
                      <li>Snapshot Voting: <span className='value'> Snapshots are taken of WBTC balances across addresses.</span></li>
                      <li>Vote Contract: <span className='value'>Deploy a smart contract that allows WBTC holders to cast their votes. The<br /> contract should track each address’s WBTC balance during the snapshot period.</span></li>
                      <li>Vote Weight: <span className='value'>Calculated based on its WBTC balance relative to the total supply of WBTC.</span></li>
                    </ul>
                  </div>
                ),
              },
              {
                label: '2024 Q1',
                children: 'Prepare of smart contracts for ethereum and voted blockchain',
              },
              {
                label: '2024 Q2',
                children: 'Smart contracts audit and airdrop for WBTC voters',
              },
              {
                label: '2024 Q3',
                children: 'Launch on testnets',
              },
              {
                label: '2024 Q4',
                children: 'Soft-launch on Ethereum Mainnet for whitelisted WBTC holders (airdrop WBTC voters)',
              },
              {
                label: '2025 Q1',
                children: 'Open to public',
              },
            ]}
          />
        </div>
      </div>
      <div className='container'>
        <div className='section-5'>
          {isDesktopOrLaptop ? (
            <Title align='left'>Frequently<br /> Asked<br /> Questions</Title>
          ) : (
            <Title align='left'>Frequently Asked<br /> Questions</Title>
          )}
          <div className='left'>
            <Collapse
              className='collapse'
              defaultActiveKey={['1']}
              items={[
                {
                  key: '1',
                  label: (
                    <div>
                      <span>What is Babel Tower?</span>
                    </div>
                  ),
                  children: (
                    <p>
                      Babel Tower is a multichain gateway, facilitating communication between blockchains like Bitcoin, Ethereum, and others. Its mission is to preserve historical significance while promoting collaboration across diverse blockchain ecosystems.
                    </p>
                  ),
                },
                {
                  key: '2',
                  label: (
                    <div>
                      <span>How does Babel Tower work?</span>
                    </div>
                  ),
                  children: (
                    <p>
                      Users can participate by depositing WBTC into the project’s liquidity pools, which will allow users to inscribe an artifact on the Bitcoin blockchain.
                    </p>
                  ),
                },
                {
                  key: '3',
                  label: (
                    <div>
                      <span>Can inscriptions be removed using Babel Tower?</span>
                    </div>
                  ),
                  children: (
                    <p>
                      Once an artifact is inscribed on the Bitcoin blockchain through Babel Tower, it becomes part of an unalterable record. Unlike NFT platforms on Ethereum that store NFT artwork or data associated with a particular NFT. Ordinals are inscribed directly onto Satoshis, stored on chains.
                    </p>
                  ),
                }
              ]} />
          </div>
        </div>
      </div>
      <div className='container app-footer-container'>
        <footer className='app-footer'>
          <div className='top'>
            <div className='left'>
              <Image className='logo' src='nav-logo.svg' />
              <span className='text'>Babel Tower</span>
            </div>
            <div className='right'>
              <a className='top' href='mailto:business@babeltower.io'>business@babeltower.io</a>
              <div className='bottom'>
                {[
                  {
                    icon: (
                      <path d="M17.1761 4.5H19.9362L13.9061 11.3912L21 20.7686H15.4456L11.0951 15.0812L6.11723 20.7686H3.35544L9.80517 13.3976L3 4.5H8.69545L12.6279 9.69843L17.1761 4.5ZM16.2073 19.1167H17.7368L7.86441 6.06512H6.2232L16.2073 19.1167Z" />
                    ),
                    label: 'X (Twitter) ',
                    link: 'https://twitter.com/BabelTower_io'
                  },
                  {
                    icon: (
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.58583 11.3691C7.95453 9.03021 11.5345 7.48828 13.3258 6.74329C18.4401 4.6162 19.5029 4.24671 20.1955 4.23451C20.3479 4.23182 20.6885 4.26957 20.9091 4.4486C21.0955 4.59977 21.1467 4.80398 21.1713 4.9473C21.1958 5.09062 21.2263 5.41712 21.202 5.67223C20.9249 8.58405 19.7257 15.6503 19.1156 18.9116C18.8574 20.2915 18.3491 20.7542 17.857 20.7995C16.7875 20.8979 15.9754 20.0928 14.9396 19.4138C13.3187 18.3514 12.4031 17.6901 10.8297 16.6533C9.01147 15.4552 10.1902 14.7967 11.2264 13.7205C11.4976 13.4389 16.2097 9.15322 16.3009 8.76444C16.3123 8.71581 16.3228 8.53457 16.2152 8.43887C16.1075 8.34316 15.9486 8.37589 15.8339 8.40192C15.6713 8.43881 13.0819 10.1502 8.0658 13.5359C7.33082 14.0406 6.6651 14.2865 6.06864 14.2736C5.41108 14.2594 4.14621 13.9018 3.20591 13.5962C2.0526 13.2213 1.13596 13.0231 1.21578 12.3865C1.25736 12.0549 1.71404 11.7158 2.58583 11.3691Z" />
                    ),
                    label: 'Telegram'
                  },
                  {
                    icon: (
                      <path d="M19.3034 5.33716C17.9344 4.71103 16.4805 4.2547 14.9629 4C14.7719 4.32899 14.5596 4.77471 14.411 5.12492C12.7969 4.89144 11.1944 4.89144 9.60255 5.12492C9.45397 4.77471 9.2311 4.32899 9.05068 4C7.52251 4.2547 6.06861 4.71103 4.70915 5.33716C1.96053 9.39111 1.21766 13.3495 1.5891 17.2549C3.41443 18.5815 5.17612 19.388 6.90701 19.9187C7.33151 19.3456 7.71356 18.73 8.04255 18.0827C7.41641 17.8492 6.82211 17.5627 6.24904 17.2231C6.39762 17.117 6.5462 17.0003 6.68416 16.8835C10.1438 18.4648 13.8911 18.4648 17.3082 16.8835C17.4568 17.0003 17.5948 17.117 17.7434 17.2231C17.1703 17.5627 16.576 17.8492 15.9499 18.0827C16.2789 18.73 16.6609 19.3456 17.0854 19.9187C18.8152 19.388 20.5875 18.5815 22.4033 17.2549C22.8596 12.7341 21.6806 8.80747 19.3034 5.33716ZM8.5201 14.8459C7.48007 14.8459 6.63107 13.9014 6.63107 12.7447C6.63107 11.5879 7.45884 10.6434 8.5201 10.6434C9.57071 10.6434 10.4303 11.5879 10.4091 12.7447C10.4091 13.9014 9.57071 14.8459 8.5201 14.8459ZM15.4936 14.8459C14.4535 14.8459 13.6034 13.9014 13.6034 12.7447C13.6034 11.5879 14.4323 10.6434 15.4936 10.6434C16.5442 10.6434 17.4038 11.5879 17.3825 12.7447C17.3825 13.9014 16.5548 14.8459 15.4936 14.8459Z" />
                    ),
                    label: 'Discord'
                  }
                ].map(({ icon, label, link }) => {
                  if (!link) {
                    return null
                  }
                  return (
                    <a href={link} target='_blank'>
                      <svg key={label} className='icon' width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        {icon}
                      </svg>
                    </a>
                  )
                })}
              </div>
            </div>
          </div>
          <div className='bottom'>
            © 2023 Babel Tower. All rights reserved
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
