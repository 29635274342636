import React, { ImgHTMLAttributes } from 'react';
import { useMediaQuery } from 'react-responsive';


const Image: React.FC<ImgHTMLAttributes<{}>> = function ({ src, ...restProps }) {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });

    if (isDesktopOrLaptop) {
        return (
            <img {...restProps} src={process.env.PUBLIC_URL + '/imgs/pc/' + src} />
        )
    }
    return (
        <img {...restProps} src={process.env.PUBLIC_URL + '/imgs/mobile/' + src} />
    )
}

export default Image;
